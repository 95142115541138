
import React, { useState } from 'react';

const ContactForm = ({ formName }) => {

    const validEmailRegex =  /^\S+@\S+\.\S+$/;

    const [value, setValue] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [error, setError] = useState({
        name: '',
        email: '',
        message: ''
    });
    
    const handleFocusLost = (textInput) => {
        if (textInput.length < 3) {
            setError({...error, name: `Merci d'entrer un nom valide`})
        }
        else {
            setError({...error, name: ''})
        }
    }

    const handleEmail = (emailInput) => {
        if(!validEmailRegex.test(emailInput)) {
            setError({...error, email: `Merci d'entrer un e-mail correct`})
        } else {
            setError({...error, email: ''})
        } 
    }

    const handleMessage = (message) => {
        if(message.length < 8) {
            setError({...error, message: `Merci d'entrer un message plus long`})
        } else {
            setError({...error, message: ''})
        } 
    }

    const handleSubmit = (evt) => {
        Object.values(error).forEach((val) => {
            if (val.length > 0 || value.length === 0) {
                evt.preventDefault();
            }
        })

    }

    let msg = 'Votre message'
    if(formName === 'commande') { msg = 'Votre commande'}

    return(
        <form
            className="contact-form"
            name={formName}
            data-netlify="true"
            data-netlify-honeypot="bot-field" 
            method="POST" 
            onSubmit={handleSubmit}>

            <input type="hidden" name="bot-field" />
            <label>Nom
            {error.name && <p className="error-message">{error.name}</p>}
            <input type="text" name="name" onChange={event => setValue({...value, name: event.target.value})} onBlur={event => {handleFocusLost(event.target.value)}} /></label>
            

            <label>E-mail
            {error.email && <p className="error-message">{error.email}</p> }
            <input type="email" name="email" onChange={event => setValue({...value, email: event.target.value})} onBlur={event => {handleEmail(event.target.value)}} /></label>
            
            <label>{msg}
            {error.message && <p className="error-message">{error.message}</p>}
            <textarea   name="message" 
                        onChange={event => setValue({...value, message: event.target.value})} 
                        onBlur={event => handleMessage(event.target.value)} ></textarea></label>
            <button type="submit" value="submit" >Envoyer</button>

        </form>
    )
}

export default ContactForm;