import React from 'react'

const VinAuVerre = ({ encaveur, cepage }) => {

    return(
        <div className="vin-verre">
            <h4>
                { encaveur }
            </h4>  
            <p>
                { cepage }
            </p>
        </div>
    )
}

export default VinAuVerre;