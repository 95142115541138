import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import Menu from '../components/Menu';
import VinAuVerre from '../components/vinAuVerre';
import Seo from '../components/SEO';

const Carte = ({ data }) => {
    // récupération de la carte via GraphQL
    const carte = data.menu.edges[0].node.frontmatter
    const mets = carte.mets
    // Création dynamique des promotions via graphQL
    // const Promotions = data.promotions.edges
    //     .map((promo) => Date.parse(promo.node.frontmatter.endDate) > Date.now() &&
    //     <Promotion  titre={promo.node.frontmatter.title} 
    //                 key={promo.node.frontmatter.title} 
    //                 description={promo.node.frontmatter.description}
    //                 image={promo.node.frontmatter.image} />)

    //récupération du tableau d'information sur les vins au Verre
    const vinsVerre = carte.vins_verre.map((vin) => <VinAuVerre cepage={vin.vin_verre.nom_cepage} key={vin.vin_verre.nom_cepage} encaveur={vin.vin_verre.nom_encaveur}/>)


// // Permet de checker si une promo est dépassée
//   function checkFalse(val) {
//     return val === false
//   }
    
    return(
        <Layout>
          <Seo title="Carte" />
            <div className="main-container">
                <PageTitle title="Notre carte" />
                <h2 className="section-title" style={{margin: '1.5em 0'}}>Vins au verre</h2>
                <div className="vins-verre">
                  { vinsVerre }
                </div>
            </div>

            <div className="grey-bg">
                <div className="main-container">
                    <h2 className="section-title" style={{paddingTop: '.5em'}}>
                        Carte des Vins et Mets
                    </h2>
                    {<Menu carte={carte} mets={mets} />}
                </div>
            </div> 

            {/* <div className="main-container" style={{marginBottom: '5vh'}}>
                <h2 className="section-title" >
                    Promotions
                </h2>
                {Promotions.every(checkFalse) ? <p>Il n'y a pas de promotions pour le moment</p> : Promotions}
            </div>            */}
        </Layout>
    )
}

export default Carte;

export const pageQuery = graphql`
{
    promotions: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/promotions/"}}) {
        edges {
        node {
            frontmatter {
                title
                description
                endDate
                image {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
        }
    },

    menu: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/carte/"}}) {
      edges {
        node {
          frontmatter {
            vinsPdf {
              publicURL
            }
            vins_verre {
              vin_verre {
                nom_encaveur
                nom_cepage
              }
            }
            mets {
              met {
                nomMet
                metPrice
              }
            }
          }
        }
      }
    }
  }
`