import React from 'react';
import ContactForm from './ContactForm';
const Menu = ({ carte, mets }) => {


    // Récupération et traitement des string pour les display dans le bon format
    // Transformation en tableau puis retraité html

    // const boissons = carte.boissons.split(';')
    //     .map((boisson) => <li>{boisson}</li>)

    // const emporter = carte.emporter.split(';')
    //     .map((togo) => <li>{togo}</li>)

    const metsDisplay = mets.map((el) => <li className="met"><span>{el.met.nomMet}</span><span>{el.met.metPrice}.-</span></li>)

    return(
        <div className="menu">
            <div className="vins-form">
                <div className="vin-left">
                    <h3 className="menu-title">
                        Vins en vente & Vins à l'emporter
                    </h3>
                    <p>Retrouvez la gamme de tous les vins en vente au Caveau de Saillon. Vous pouvez également les commander et vous les faire livrer en remplissant le formulaire ci-contre</p>

                    <a href={carte.vinsPdf.publicURL}><button>Découvrez la carte des vins</button></a>
                </div>
                <div className="vin-right">
                    <ContactForm formName="commande" />
                </div>
            </div>
            <div className="mets">
                <h3 className="menu-title">
                    Mets
                </h3>
                <ul>
                    {metsDisplay}
                </ul>
            </div>
            
        </div>
    )
}

export default Menu;